import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/LayoutDefault";
import HomePage from "../template/pages/Home";
import SEO from "../components/SEO";

const MainPage = (props) => {
    return <>
      <Layout {...props}>
          <SEO title={'Retain Rocket | Lower your SaaS Churn'} />
          <HomePage {...props} />
      </Layout>
    </>
}

export default MainPage;
