import React from 'react';

import '../css/style.scss';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import FeaturesHome from '../partials/FeaturesHome';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import FeaturesWorld from '../partials/FeaturesWorld';
import News from '../partials/News';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';
import PricingTable from '../partials/PricingTable';
import Faqs from '../partials/Faqs';

function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page sections */}
        <HeroHome />
        <FeaturesHome />
        <section className="bg-gradient-to-b from-white to-gray-100">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-12">
                    <PricingTable />
                </div>
            </div>
        </section>
        <Faqs />
        {/* <FeaturesBlocks /> */}
        {/* <FeaturesWorld /> */}
        {/* <News /> */}
        <Cta />

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;
